<template lang="pug">
main
  h1.title.null-hint(v-if="!isLoading && !Object.keys(contract).length") 找不到對應方案合約資料
  .hasContract(v-if="Object.keys(contract).length")
    .contract-card.rule
      h1.title 學習約定條款與上課聲明
      h2.product.name {{ contract.productName }}

      .info
        p.detail 學員名字:&thinsp;
          span.chineseName {{ vip.chinese_name }}
          span.englishName &thinsp;({{ vip.english_name }})

        p.detail 購買堂數:&thinsp;{{ contract.numSession }} 堂
        p.detail 課程期間:&thinsp;{{ contract.origTerm }} 個月

        p.detail 贈送堂數:&thinsp;{{ contract.giftSession }} 堂
        p.detail 贈送期間:&thinsp;{{ contract.giftTerm }} 個月

        p.detail 課程總堂數:&thinsp;{{ contract.totalSession }} 堂
        p.detail 總課程期間:&thinsp;{{ contract.totalTerm }} 個月

        p.detail 學習期間:&thinsp;
          span.learnTime {{ contract.learningTime[0].learningTime }}

        p.detail 方案金額:&thinsp;{{ contract.origPrice }}
        p.detail 總折扣金額:&thinsp;{{ discountSum(contract) }}

      p.product.price 課程優惠價 ${{ contract.sellingPrice }}

    .contract-card
      .v-html(v-html="cleanStyle(contract.contract)")
      p.text-center
        | 您已於&thinsp;
        span.learnRule {{ contract.learnRule }}
        | &thinsp;審慎閱讀完畢並同意學習條款之內容

      //- 浮水印
      img.jr-logo(src="https://cdn3.wuwow.tw/arts/contract/wuwow-junior-logo.png", alt="jr-logo")
      //- 印章
      img.contract-seal(src="https://cdn3.wuwow.tw/arts/contract/lioshutan-contract-seal.png", alt="六書合約印章")

  //- 無合約，舊合約身分，上課聲明
  template(v-if="!isLoading && !Object.keys(contract).length && newContract===false")
    p.title  WUWOW Jr 上課聲明
    p.text-center （ 不適用 Wushare 學員）
    section.v-html
      div
        p 服務內容：
          div
            ol
              li WUWOW Jr 提供本服務之網站網址：https: //www.wuwowjr.tw/
              li WUWOW Jr 提供本服務之適⽤對象：四歲以上；十五歲以下者
              li WUWOW Jr 提供本服務之教學內容：依單元主題教材內容⽽定，除上傳自備教材外，學員不可排除或要求略過WUWOW Jr原定順序之教材，且不得排除任一授課顧問。
              li 情境主題教材，學員、教材、顧問三方適性分析完美媒合。
              li 每堂課程後，提供完整六大面向英文程度報告，隨時掌握學習進度。
              li 每堂課程後，可針對顧問、教材、系統三方面作評鑑反饋，為您的學習品質把關。
              li 課後錄影檔7天內免費觀看複習。
              li 課程專屬服務，學員可寫信寄至 jrpm@lioshutan.com 諮詢學習疑問。
      div
        p 課程種類：
          div
            ol
              li 【一般課程】：專屬一位學員的家教班級，由系統媒合WUWOW Jr教材；如欲指定顧問，需於72小時前預約，每次扣除課堂數為一堂。
              li 【客製化課程】：可上傳自備教材並同時指定顧問，需於72小時前預約，每次扣除課堂數為兩堂，預約成功後不得取消課程。
              li 【特殊時段申請】：可向顧問申請額外加開授課時段(需符合原訂WUWOW Jr開課時段，但顧問未開放授課之條件)，可同時上傳自備教材；需於72小時前預約，每次扣除課堂數為兩堂，預約成功後不得取消。顧問將於課前36小時於系統回覆是否接受加開申請，如未申請成功，則不需計算堂數。
      div
        p 訂課及取消方式：
          div
            ol
              li 開課時段為每半小時開課，每堂課程25分鐘。開課時段分為周一至周五中午12:30至晚上21:00；每周六、周日與每年七、八月整月份開課時間為早上09:00至晚上21:00。每年寒假期間開課時間為早上09:00至晚上21:00，確切月份日期將另行公告於官網。
              li 09:00-12:00的課程需於前日21:00前完成預約；12:30-18:00的課程需於當日中午11:00前完成預約；18:30-21:00的課程需於當日下午17:00前完成預約。
              li 【一般課程-指定顧問、客製化課程、特殊時段申請】需於課前72小時預約。
              li 預約【一般課程】可於開課前15分鐘登入個人學習系統取消課程，不扣堂數。
              li 預約【客製化課程、特殊時段申請】不得取消課程。
      div
        p 使用規範：
          div
            ol
              li 進入教室時間：開課前5分鐘~開課後3分鐘，請準時進入教室。如超過此區間則不得進入教室，且堂數照常計算。
              li 請勿於課程期間分心、使用手機或與旁人聊天，影響課程進行。
              li 需使用耳機與麥克風，確保收音品質。
              li 請勿擅自破壞、修改網路內容或蓄意散佈干擾網路系統正常運作之電腦程式（含病毒）。
              li 不得利用線上系統施行言語或文字有性別、種族、政治、宗教歧視或散佈不實、不當（騷擾、謾罵、攻擊、恐嚇、毀謗、侵犯他人隱私、色情或其他違反公序良俗…等）之言論、文字、圖片或影像或其他不受歡迎之行為。
              li 不得將會籍資格與可使用堂數進行出售、贈與、拍賣、出借等方式予以他人使用，影響 WUWOW 作業系統及其他會員權益，如經發現，WUWOW 得逕行停止提供課程服務。
              li 承接者未購買單一合約，故不得申請終止學習、不得申請暫停學習期限，且承接之堂數與期限皆不可轉回主約人或異動。
              li 如學員於契約結束日仍有剩餘堂數未使用，WUWOW Jr同意學員或承接者得以$1,000 延長一個月學習期限（主約人及承接者須各自付費辦理，雙方付費延長之上限共六個月），或以4堂課程兌換一個月學習期限(主約人及承接者須各自兌換辦理，雙方可兌換之上限共24堂)。契約延長月份需從到期日開始接續，不得中斷期限。延期需於到期日前提出申請，到期日後則不得再提出申請。
              li 若 WUWOW 因相關電信業者網際網路系統故障，或相關系統設備損壞維修、維護或更換等障礙因素造成服務中斷，WUWOW將於系統公告，並於系統恢復後五天內補回承接分享者已扣之堂數;同樣，承接分享者需為天災以外之環境因素(如網路頻寬、載具與語音相關設備等)負責，確保個人學習品質狀況。
              li 承接分享者同意無償提供註冊登載於 WUWOW 之個人資料予 WUWOW 蒐集電腦處理及作為接收 WUWOW 發行之電子報、產品資訊、服務更新等各項服務與資訊之用。
              li 雙方同意因本合約所生之爭議而申請法院調解或提起訴訟請求時，以中華民國之法律為準據法，並以臺灣臺中地方法院為第一審管轄法院。
      div
        p 設備規格：
          div
            table
              tbody
                tr
                  td
                    p 視訊系統
                  td
                    p 電腦
                    ul
                      li Mac OS 10.12+
                      li Windows 7+
                    p 手機
                    ul
                      li Android 5.0+
                      li IOS 9.0+
                tr
                  td
                    p 瀏覽官網
                  td
                    ul
                      li Chrome 86+
                      li Safari 13.1.1+
                      li Firefox 81.0.2+
                tr
                  td
                    p 網路速度
                  td
                    ul
                      li 光纖網路 上傳6MB+ 下載6MB+
                      li ⾏動網路 4G+

  //- 無合約，舊合約身分，上課聲明
  template(v-if="!isLoading && !Object.keys(contract).length && newContract===true")
    p.title WUWOW Jr 上課聲明
    p.text-center  （ 不適用 Wushare 學員）
    section.v-html
      div
        p 服務內容：
          div
            ol
              li WUWOW Jr 提供本服務之網站網址：https: //www.wuwowjr.tw/
              li WUWOW Jr 提供本服務之適⽤對象：四歲以上；十五歲以下者
              li WUWOW Jr 提供本服務之教學內容：依單元主題教材內容⽽定，除上傳自備教材外，學員不可排除或要求略過WUWOW Jr原定順序之教材，且不得排除任一授課顧問。
              li 情境主題教材，學員、教材、顧問三方適性分析完美媒合。
              li 每堂課程後，提供完整六大面向英文程度報告，隨時掌握學習進度。
              li 每堂課程後，可針對顧問、教材、系統三方面作評鑑反饋，為您的學習品質把關。
              li 課後錄影檔7天內免費觀看複習。
              li 課程專屬服務，學員可寫信寄至 jrpm@lioshutan.com 諮詢學習疑問。
      div
        p 課程種類：
          div
            ol
              li 【一般課程】：專屬一位學員的家教班級，由系統媒合WUWOW Jr教材；如欲指定顧問，需於72小時前預約，每次扣除課堂數為一堂。
              li 【客製化課程】：可上傳自備教材並同時指定顧問，需於72小時前預約，每次扣除課堂數為兩堂，預約成功後不得取消課程。
              li 【特殊時段申請】：可向顧問申請額外加開授課時段(需符合原訂WUWOW Jr開課時段，但顧問未開放授課之條件)，可同時上傳自備教材；需於72小時前預約，每次扣除課堂數為兩堂，預約成功後不得取消。顧問將於課前36小時於系 是否接受加開申請，如未申請成功，則不需計算堂數。
      div
        p 訂課及取消方式：
          div
            ol
              li 開課時段為每半小時開課，每堂課程25分鐘。開課時段分為周一至周五中午12:30至晚上21:00；每周六、周日與每年七、八月整月份開課時間為早上09:00至晚上21:00。每年寒假期間開課時間為早上09:00至晚上21:00，確切月 將另行公告於官網。
              li 09:00-12:00的課程需於前日21:00前完成預約；12:30-18:00的課程需於當日中午11:00前完成預約；18:30-21:00的課程需於當日下午17:00前完成預約。
              li 【一般課程-指定顧問、客製化課程、特殊時段申請】需於課前72小時預約。
              li 預約【一般課程】可於開課前15分鐘登入個人學習系統取消課程，不扣堂數。
              li 預約【客製化課程、特殊時段申請】不得取消課程。
      div
        p 使用規範：
          div
            ol
              li 進入教室時間：開課前5分鐘~開課後3分鐘，請準時進入教室。如超過此區間則不得進入教室，且堂數照常計算。
              li 請勿於課程期間分心、使用手機或與旁人聊天，影響課程進行。
              li 需使用耳機與麥克風，確保收音品質。
              li 請勿擅自破壞、修改網路內容或蓄意散佈干擾網路系統正常運作之電腦程式（含病毒）。
              li 不得利用線上系統施行言語或文字有性別、種族、政治、宗教歧視或散佈不實、不當（騷擾、謾罵、攻擊、恐嚇、毀謗、侵犯他人隱私、色情或其他違反公序良俗…等）之言論、文字、圖片或影像或其他不受歡迎之行為。
              li 不得將會籍資格與可使用堂數進行出售、贈與、拍賣、出借等方式予以他人使用，影響 WUWOW 作業系統及其他會員權益，如經發現，WUWOW 得逕行停止提供課程服務。
              li 承接者未購買單一合約，故不得申請終止學習、不得申請暫停學習期限，且承接之堂數與期限皆不可轉回主約人或異動。
              li 如學員於契約結束日仍有剩餘堂數未使用，WUWOW Jr同意學員或承接者得以$1,000 延長一個月學習期限（主約人及承接者須各自付費辦理，雙方付費延長之上限共六個月），或以4堂課程兌換一個月學習期限(主約人及承接者須各  辦理，雙方可兌換之上限共24堂)。契約延長月份需從到期日開始接續，不得中斷期限。延期需於到期日前提出申請，到期日後則不得再提出申請。
              li 若 WUWOW 因相關電信業者網際網路系統故障，或相關系統設備損壞維修、維護或更換等障礙因素造成服務中斷，WUWOW將於系統公告，並於系統恢復後五天內補回承接分享者已扣之堂數;同樣，承接分享者需為天災以外之環境因素( 頻寬、載具與語音相關設備等)負責，確保個人學習品質狀況。
              li 承接分享者同意無償提供註冊登載於 WUWOW 之個人資料予 WUWOW 蒐集電腦處理及作為接收 WUWOW 發行之電子報、產品資訊、服務更新等各項服務與資訊之用。
              li 雙方同意因本合約所生之爭議而申請法院調解或提起訴訟請求時，以中華民國之法律為準據法，並以臺灣臺中地方法院為第一審管轄法院。
      div
        p 設備規格：
          div
            table
              tbody
                tr
                  td
                    p 視訊系統
                  td
                    p 電腦
                    ul
                      li Mac OS 10.12+
                      li Windows 7+
                    p 手機
                    ul
                      li Android 5.0+
                      li IOS 9.0+
                tr
                  td
                    p 瀏覽官網
                  td
                    ul
                      li Chrome 86+
                      li Safari 13.1.1+
                      li Firefox 81.0.2+
                tr
                  td
                    p 網路速度
                  td
                    ul
                      li 光纖網路 上傳6MB+ 下載6MB+
                      li ⾏動網路 4G+

</template>

<script scoped>
import { mapState, mapActions } from 'vuex';

import _ from 'lodash';
const $_ = _;

import WuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';

import swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

export default {
  name: 'ProgramContractView',

  mixins: [WuwowLoadingMixin],

  data() {
    return {
      isLoading: false,
      contract: {},
      vip: {},
      // 預設值
      select: {
        page: 1,
        perPage: 1,
      },
      // 新舊合約
      newContract: false,
    };
  },

  computed: {
    ...mapState({
      contractOwnHistory: (state) => state.vipContract.contractOwnHistory,
      baseProfile: (state) => state.vipAccount.baseProfile,
      customizedClassroomRule: (state) => state.vipAccount.customizedClassroomRule,
    }),
  },

  async created() {
    this.select = {
      page: this.$route.query.page,
      perPage: this.$route.query.perPage,
    };
    this.getContract();
  },

  methods: {
    ...mapActions('vipContract', ['fetchContractOwnHistory']),
    ...mapActions('vipAccount', ['getVipAccountBaseProfile']),

    async getContract() {
      const tag = this.startLoading();
      this.isLoading = true;
      try {
        const params = {
          page: this.select.page,
          perPage: this.select.perPage,
        };

        await this.getVipAccountBaseProfile();
        this.vip = this.baseProfile;

        await this.fetchContractOwnHistory(params);
        const contractData = JSON.parse(JSON.stringify(this.contractOwnHistory));
        const contractResult = this.deepCamelCaseKeys($_.head(contractData.data.contract));
        this.contract = contractResult;

        // 若查無合約，則查詢身分權限，用切換上課聲明版本
        if (!this.contract) {
          this.newContract = this.customizedClassroomRule.new_rule;
        }
      } catch (error) {
        console.error(error);
        swal.fire({
          icon: 'error',
          title: '讀取合約錯誤',
          text: `${error}`,
        });
      } finally {
        this.isLoading = false;
        this.endLoading(tag);
      }
    },
    // 遞迴處理物件，將鍵值改為小駝峰
    deepCamelCaseKeys(obj) {
      switch (true) {
        // 陣列
        case $_.isArray(obj):
          return $_.map(obj, this.deepCamelCaseKeys);
        // 物件
        case $_.isObject(obj):
          return $_.mapValues(
            $_.mapKeys(obj, (value, key) => $_.camelCase(key)),
            (value) => {
              if (value === null) {
                return '';
              }
              return this.deepCamelCaseKeys(value);
            }
          );
        // 其他情況直接返回原始值
        default:
          return obj;
      }
    },

    discountSum(data) {
      let totalDiscount = 0;
      // 基本折扣
      const discPrice = Number(data.discPrice);
      // 一次付清折扣
      const onePayDiscPrice = Number(data.onePayDiscPrice);
      // 其他折扣
      const otherDisc = Number(data.otherDisc);
      // 續約折扣
      const renewalDisc = Number(data.renewalDisc);
      // 業務權限折扣
      const bdQuota = Number(data.bdQuota);
      totalDiscount = Number(discPrice + bdQuota + onePayDiscPrice + otherDisc + renewalDisc);
      return totalDiscount;
    },

    // 清除 style 標籤及標籤內部內容
    cleanStyle(htmlString) {
      let htmlContent = htmlString;

      const styleRule = /<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi;
      htmlContent = htmlContent.replaceAll(styleRule, '');

      return htmlContent;
    },
  },

};
</script>

<style lang="scss">
/* http://meyerweb.com/eric/tools/css/reset/
  v2.0 | 20110126
  License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* reset CSS end */

body {
  background-color: #f4f1ee;
}

main {
  font-family: "Noto Sans TC";
  margin:30px 0;
}

.title {
  text-align: center;
  color: dimgrey;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.5rem;
}

.null-hint {
  text-align: center;
  color: crimson;
  margin: 1.5rem auto;
}

.hasContract {
  margin: 0 auto;
  padding: 0 15px;

  font-size: 0.875rem;
  line-height: 1.5;

  @media screen and (min-width:576px) {
    max-width: 540px;
  }

  @media screen and (min-width:768px) {
    max-width: 720px;
  }

  @media screen and (min-width:992px) {
    max-width: 960px;
  }

  @media screen and (min-width:1200px) {
    max-width: 1140px;
  }
}

.contract-card {
  border-radius: 4px;
  margin-bottom: 30px;
  background-color: #fff;
  padding: 30px;
  max-width: 100%;

  &.rule {
    .title {
      font-size: 1.3125rem;
      margin-bottom: 1rem;
      text-align: center;
      color: #454543;
      letter-spacing: 3px;
      font-weight: 500;
      line-height: 1.2;
    }

    .product {
      text-align: center;
      color: #debf69;
      font-size: 1.125rem;
      letter-spacing: 2px;

      &.name {
        margin-bottom: 2rem;
      }
    }

    .info {
      display: flex;
      margin: 0 auto 1rem;
      max-width: 360px;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 0;

      .detail {
        color: #454543;
        letter-spacing: 1px;
        margin-bottom: 0.5rem;
        width: 50%;

        &:has(.chineseName, .englishName),
        &:has(.learnTime) {
          width: 100%;
          /* text-align: center; */
        }
      }
    }
  }

  &:has(.v-html) {
    position: relative;

  .wowow-logo,
  .jr-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      min-width: 100px;

      pointer-events: none;
      opacity: 0.2;
    }

    .contract-seal {
      position: relative;
      right: 20px;
      top: -100px;

      display: block;
      margin-left: auto;
      width: 20%;
      min-width: 50px;
      max-width: 400px;

      // opacity: 20%;
    }

    p:has(.learnRule) {
      margin-top: 2rem;
      border-radius: 0.25rem;
      padding: 0.75rem 1.25rem;
      background-color: #e0f3f8;
      color: #336573;
    }
  }
}

.v-html {
  ol {
    list-style: decimal;
    margin: revert;
    padding: revert;
  }

  ul {
    list-style: disc;
    margin: revert;
    padding: revert;
  }

  table {
    width: 100%;

    th {
      border: 1px solid #bfbfbf;
      padding: 0.75rem;
    }

    td {
      border: 1px solid #bfbfbf;
      padding: 0.75rem 1.25rem;
    }
  }

  a {
    color: #4c41c1;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 0.5rem;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 21px;
  }

  h3 {
    font-size: 16.38px;
  }

  h4, h5, h6, p, ol, ul, th, td {
    font-size: 14px;
    line-height: 20px;
  }

  img {
      display: block;
    }
}

// 網頁列印pdf時須設置的畫面初始化
@page {
  size: A4 portrait;
  margin: 0mm !important;
}

// 網頁列印pdf時，CSS須寫在@media print內
@media print {
.v-html {
  ol {
    list-style: decimal;
    margin: revert;
    padding: revert;
  }

  ul {
    list-style: disc;
    margin: revert;
    padding: revert;
  }

  table {
    width: 100%;

    th {
      border: 1px solid #bfbfbf;
      padding: 0.75rem;
    }

    td {
      border: 1px solid #bfbfbf;
      padding: 0.75rem 1.25rem;
    }
  }

  a {
    color: #4c41c1;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 0.5rem;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 21px;
  }

  h3 {
    font-size: 16.38px;
  }

  h4, h5, h6, p, ol, ul, th, td {
    font-size: 14px;
    line-height: 20px;
  }

  img {
      display: block;
    }
  }
}
</style>
